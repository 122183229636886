import { makeApiRequest } from '@/src/lib/api-request'
import { useMutation } from '@tanstack/react-query'
interface ContactDetails {
  email: string}

export const useNewsletterMutation = () => {
  return useMutation({
    mutationFn: async (props: ContactDetails) => {
      await makeApiRequest({
        url: '/Landing/addNewsLetter',
        method: 'POST',
        data: {
          email: props.email,
        },
        isAuthenticated: false,
        fallbackError: 'Failed to send contact details',
        isV3Api: true,
      })
    },
  })
}
