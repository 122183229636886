import { makeApiRequest } from '@/src/lib/api-request'

import { Video } from '@/src/types/video'
import { useQuery } from '@tanstack/react-query'
import { useLocationQuery } from '../guest/location-query'

export const usePopularVideosQuery = () => {
  const { data: data, isFetched: isLocationFetched } = useLocationQuery()

  return useQuery({
    queryKey: [
      'popular-videos',
      'landing',
      data?.city,
      data?.country,
      data?.countryCode,
    ],
    queryFn: async () => {
      const dataProps = {
        limit: 8,
        offset: 0,
        city: data?.city || '',
        country: data?.country || '',
        countryCode: data?.countryCode || '',
        languageId: '',
        interestId: '',
        categoryId: null,
      }
      const response = await makeApiRequest({
        method: 'POST',
        url: '/Landing/getPopularTrendingVideos_Withoutlogin',
        isAuthenticated: false,
        data: dataProps,
        fallbackError: 'Error while fetching popular videos',
        isV3Api:true
      })
      const videos: Video[] = response.response.data
      return { videos }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
    enabled: !!isLocationFetched,
  })
}
